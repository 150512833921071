<template>
    <nav
        :id="id"
        v-click-outside="escapeMenu"
        :class="menuOpen && '--menu-open'"
        class="lni-c-mega-menu"
        @keydown.esc.exact="escapeMenu">
        <button
            v-show="!isWide"
            ref="mobileOpenButton"
            class="lni-c-mega-menu__main-toggle lni-u-mlauto"
            aria-label="Main Menu"
            aria-controls="lni-mega-menu"
            :aria-expanded="menuOpen"
            @click="toggleMobileMenu">
            <span
                v-show="!isWide"
                aria-hidden="true"
                class="lnicon--menu lni-u-type--xl"></span>
            <span class="lni-u-visually-hidden --focusable">Menu</span>
        </button>
        <div class="lni-c-mega-menu__outer-wrapper">
            <transition name="slide-from-right">
                <div
                    v-show="menuOpen || isWide"
                    class="lni-c-mega-menu__inner-wrapper"
                    :class="`--currentLevel${currentLevel}`">
                    <div
                        id="lni-mega-menu"
                        class="lni-c-mega-menu__level --level1">
                        <lni-mega-menu-heading
                            v-show="!isWide"
                            ref="level1Header"
                            :level="1"
                            @click="goBack(1)"
                            @shiftTabFromStart="sendFocusToEnd($event)">
                            <form
                                class="lni-c-site-header__search-container lni-u-flex lni-u-items-center lni-u-ma0"
                                method="GET"
                                :action="`${currentEnvironment}/agency/site-search`">
                                <!-- TODO: use component and accessible markup -->
                                <label
                                    for="siteSearchMobile"
                                    class="lni-u-visually-hidden">Search</label>
                                <input
                                    id="siteSearchMobile"
                                    placeholder="Search"
                                    type="search"
                                    name="query">
                                <input
                                    type="hidden"
                                    name="index"
                                    value="All_Content">
                                <input
                                    type="hidden"
                                    name="pg"
                                    value="1">
                                <input
                                    type="hidden"
                                    name="group"
                                    value="10">
                                <button aria-label="Search">
                                    <span
                                        :id="id + '_icon--search'"
                                        aria-hidden="true"
                                        class="lni-c-icon lnicon--search"></span>
                                </button>
                            </form>
                        </lni-mega-menu-heading>
                        <ul
                            class="lni-c-mega-menu__list --level1"
                            :class="!isWide?'lni-u-list-reset lni-u-ma2 lni-u-pa2':''">
                            <li
                                v-for="(item, index) in navigation"
                                :key="item.id">
                                <button
                                    v-show="isWide || currentLevel === 1"
                                    :ref="item.id"
                                    :key="`tab${item.id}`"
                                    :aria-controls="`menu-item_${item.id}`"
                                    :aria-expanded="item.id === level1Open"
                                    :aria-current="item.active ? 'location' : null"
                                    :class="item.active && currentLevel > 1 ? '--active' : null"
                                    class="lni-c-mega-menu__item lni-c-mega-menu__toggle --level1 lni-u-flex lni-u-items-center lni-u-pv2"
                                    @click="toggleOpen(item.id, 1, $event)"
                                    @keydown.tab.exact="item.id === '5' && isWide ? sendFocusToStart($event) : {}">
                                    <span class="lni-u-full-width lni-u-flex lni-u-items-center">
                                        <span
                                            class="lni-u-mega-menu__section-icon"
                                            :class="[`lnicon--${getIcon(index)}`, !isWide?'lni-u-type--lg lni-u-mr1':'']"
                                            aria-hidden="true"></span>
                                        <span
                                            class="lni-c-mega-menu__tab-label lni-u-anti-jank"
                                            :data-label="item.label">{{ item.label }}</span>
                                    </span>
                                    <span
                                        v-show="!isWide"
                                        aria-hidden="true"
                                        class="lnicon--caret--right lni-u-mr1"></span>
                                </button>

                                <!-- LEVEL 2 START -->
                                <transition
                                    :name="dropdownOpen && currentLevel > 1 ? 'fade-quick' : 'slide-from-top'"
                                    @after-enter="setDropdownOpenState"
                                    @after-leave="setDropdownOpenState">
                                    <div
                                        v-show="item.id === level1Open"
                                        :id="`menu-item_${item.id}`"
                                        ref="level-container"
                                        class="lni-c-mega-menu__level --level2">
                                        <lni-mega-menu-heading
                                            v-show="!isWide"
                                            :ref="`levelHeader${item.id}`"
                                            :level="2"
                                            @click="goBack(2)"
                                            @shiftTabFromStart="sendFocusToEnd($event)">
                                            <div class="flex flex-column">
                                                <strong>
                                                    <span
                                                        class="lni-u-mega-menu__section-icon"
                                                        :class="`lnicon--${getIcon(index)}`"
                                                        aria-hidden="true"></span>
                                                    {{ item.label }}
                                                </strong>
                                            </div>
                                        </lni-mega-menu-heading>
                                        <a
                                            :ref="`landing-page-link${index}`"
                                            :href="item.children[0].route"
                                            class="lni-c-mega-menu__section-link lni-u-flex lni-u-items-center lni-u-type--md">
                                            <span class="lni-u-mr1">{{ item.children[0].label }}</span>
                                            <span
                                                aria-hidden="true"
                                                class="lnicon--caret--right lni-u-mr1"></span>
                                        </a>

                                        <ul
                                            class="lni-c-mega-menu__list --level2 lni-u-list-reset"
                                            :class="!isWide?'lni-u-ma2 lni-u-pa2':''">
                                            <li
                                                v-for="level2Item in item.children.slice(1)"
                                                :key="level2Item.id"
                                                class="lni-c-mega-menu__list-item --level2">
                                                <button
                                                    v-show="currentLevel === 2 || isWide"
                                                    :ref="level2Item.id"
                                                    :key="`item_${level2Item.id}`"
                                                    class="lni-c-mega-menu__item lni-c-mega-menu__toggle --level2 lni-u-flex lni-u-items-center"
                                                    :aria-expanded="level2Item.id === level2Open"
                                                    :aria-controls="`menu-item_${level2Item.id}`"
                                                    @click="toggleOpen(level2Item.id, 2)">
                                                    <span class="lni-u-full-width">{{ level2Item.label }}</span>
                                                    <span
                                                        aria-hidden="true"
                                                        class="lnicon--caret--right lni-u-pl1 lni-u-mr1"></span>
                                                </button>
                                                <!-- LEVEL 3 START -->
                                                <transition :name="isWide ? 'slide-from-left' : ''">
                                                    <div
                                                        v-if="level2Item.children"
                                                        v-show="level2Item.id === level2Open"
                                                        :id="`menu-item_${level2Item.id}`"
                                                        class="lni-c-mega-menu__level --level3">
                                                        <lni-mega-menu-heading
                                                            v-show="!isWide"
                                                            :ref="`levelHeader${level2Item.id}`"
                                                            :label="level2Item.label"
                                                            :level="3"
                                                            @click="goBack(3)"
                                                            @shiftTabFromStart="sendFocusToEnd($event)">
                                                            <div class="flex flex-column">
                                                                <strong>
                                                                    <span
                                                                        class="lni-u-mega-menu__section-icon"
                                                                        :class="`lnicon--${getIcon(index)}`"
                                                                        aria-hidden="true"></span>
                                                                    {{ item.label }}
                                                                </strong>
                                                                <div class="breadcrumbs">
                                                                    <span>{{ level2Item.label }}</span>
                                                                </div>
                                                            </div>
                                                        </lni-mega-menu-heading>

                                                        <ul
                                                            :ref="`list${level2Item.id}`"
                                                            class="lni-c-mega-menu__list --level3"
                                                            :class="!isWide? 'lni-u-mb5 lni-u-ma2 lni-u-pv2':''">
                                                            <li
                                                                v-for="level3Item in level2Item.children"
                                                                :key="level3Item.id"
                                                                class="lni-c-mega-menu__list-item --level3"
                                                                :class="!isWide? 'lni-u-ph2':''">
                                                                <a
                                                                    v-if="!level3Item.children || isWide"
                                                                    :ref="level3Item.id"
                                                                    class="lni-c-mega-menu__item lni-c-mega-menu__link --level3"
                                                                    :href="level3Item.route"
                                                                    @keydown.tab.exact="level3Item.last && !isWide ? sendFocusToStart($event) : {}">{{ level3Item.label }}</a>
                                                                <button
                                                                    v-else
                                                                    v-show="currentLevel === 3"
                                                                    :ref="level3Item.id"
                                                                    :key="`item_${level3Item.id}`"
                                                                    class="lni-c-mega-menu__item lni-c-mega-menu__toggle --level3 lni-u-flex lni-u-items-center"
                                                                    :aria-expanded="level3Item.id === level3Open"
                                                                    :aria-controls="`menu-item_${level3Item.id}`"
                                                                    @click="toggleOpen(level3Item.id, 3)"
                                                                    @keydown.tab.exact="level3Item.last && !isWide ? sendFocusToStart($event) : {}">
                                                                    <span class="lni-u-full-width">{{ level3Item.label }}</span>
                                                                    <span
                                                                        aria-hidden="true"
                                                                        class="lnicon--caret--right lni-u-pl1 lni-u-mr1"></span>
                                                                </button>

                                                                <!-- LEVEL 4 START -->
                                                                <div
                                                                    v-if="level3Item.children && !isWide"
                                                                    v-show="level3Item.id === level3Open"
                                                                    :id="`menu-item_${level3Item.id}`"
                                                                    class="lni-c-mega-menu__level --level4">
                                                                    <lni-mega-menu-heading
                                                                        v-show="!isWide"
                                                                        :ref="`levelHeader${level3Item.id}`"
                                                                        :level="4"
                                                                        @click="goBack(4)"
                                                                        @shiftTabFromStart="sendFocusToEnd($event)">
                                                                        <div>
                                                                            <strong>{{ item.label }}</strong>
                                                                            <div>
                                                                                <span>{{ level2Item.label }}</span>
                                                                                <span
                                                                                    v-show="!isWide"
                                                                                    aria-hidden="true"
                                                                                    class="lnicon--caret--right lni-u-mlauto lni-u-mr0"></span>
                                                                                <span>{{ level3Item.label }}</span>
                                                                            </div>
                                                                        </div>
                                                                    </lni-mega-menu-heading>

                                                                    <a
                                                                        :ref="`first-item_${level3Item.id}`"
                                                                        class="lni-c-mega-menu__item --level4 lni-c-mega-menu__link"
                                                                        :href="level3Item.route">{{ level3Item.label }}</a>
                                                                    <ul
                                                                        :ref="`list${level3Item.id}`"
                                                                        class="lni-c-mega-menu__list --level4"
                                                                        :class="!isWide? 'lni-u-mb5 lni-u-ma2 lni-u-pv2':''">
                                                                        <li
                                                                            v-for="level4Item in level3Item.children"
                                                                            :key="level4Item.id"
                                                                            class="lni-c-mega-menu__list-item --level4"
                                                                            :class="!isWide? 'lni-u-ph2':''">
                                                                            <a
                                                                                :ref="level4Item.item"
                                                                                class="lni-c-mega-menu__item --level4 lni-c-mega-menu__link"
                                                                                :href="level4Item.route"
                                                                                @keydown.tab.exact="level4Item.last ? sendFocusToStart($event) : {}">{{ level4Item.label }}</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <!-- LEVEL 4 END -->
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </transition>
                                                <!-- LEVEL 3 END -->
                                            </li>
                                        </ul>
                                        <div
                                            v-if="currentLevel === 2 || currentLevel > 1 && isWide"
                                            class="lni-c-mega-menu__top-tasks lni-u-mb5">
                                            <lni-top-tasks
                                                :id="`top-task_item${item.id}`"
                                                :ref="`topTasks${index}`"
                                                :index="index"
                                                :topTasksLabel="content.topTasksLabel"
                                                :links="topTasks[index].links"
                                                :emailLink="topTasks[index].emailLink"
                                                :phoneLink="topTasks[index].phoneLink"
                                                @tabFromLastItem="sendFocusToStart($event)"></lni-top-tasks>
                                        </div>
                                    </div>
                                </transition>
                                <!-- LEVEL 2 END -->
                            </li>
                        </ul>
                        <lni-courtesy-menu
                            v-if="currentLevel === 1 && !isWide"
                            id="courtesy_menu"
                            ref="courtesyMenu"
                            :origin="origin"
                            :content="content"
                            class="lni-c-mega-menu__courtesy-menu lni-u-mb5"
                            @tabFromLastItem="sendFocusToStart($event)"></lni-courtesy-menu>
                    </div>
                </div>
            </transition>
        </div>
    </nav>
</template>

<script>
/*
TODO: Add uid to items in API
TODO: Make top tasks slide in smoothly (mobile animation)

NOTE: The desktop dropdown needs a fixed height, because we are absolutely positioning a nested list
*/
import LniMegaMenuHeading from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-mega-menu/lni-mega-menu-heading.vue';
import LniCourtesyMenu from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-courtesy-menu/lni-courtesy-menu.vue';
import LniTopTasks from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-top-tasks/lni-top-tasks.vue';
import clickOutside from '@gov.wa.lni/framework.one-lni.directives/source/clickOutside.js';

const body = document.querySelector('body');

export default {
    name: 'lni-mega-menu',
    components: {
        LniMegaMenuHeading,
        LniCourtesyMenu,
        LniTopTasks,
    },
    directives: {
        clickOutside,
    },
    props: {
        navigation: {
            type: Array,
            required: true,
        },
        id: {
            type: String,
            required: true,
        },
        content: {
            type: Object,
            required: true,
        },
        topTasks: {
            type: Array,
            required: true,
        },
        location: {
            type: String,
            default: '',
        },
        origin: {
            type: String,
            default: 'https://www.lni.wa.gov/',
        },
    },
    data() {
        return {
            currentEnvironment: this.$oneLni.environment.current.apiLocation
                .replace('/application/api', ''),
            // processed/enhanced data
            itemsWithIds: [],
            dropdownOpen: false,
            hasFocus: false,
            // level[N]Open is set to the id of the currently open menus
            level1Open: null,
            level2Open: null,
            level3Open: null,
            menuToggleOpen: false,
            isWide: true,
            megaMenuBreakpoint: 715,
            sections: [
                'safety-health',
                'claims',
                'patient-care',
                'insurance',
                'workers-rights',
                'licensing-permits',
            ],
        };
    },
    computed: {
        currentLevel() {
            return (
                [this.level1Open, this.level2Open, this.level3Open].filter(
                    openId => openId !== null,
                ).length + 1
            );
        },
        currentOpenId() {
            return this[`level${this.currentLevel - 1}Open`];
        },
        currentLevelStart() {
            if (this.currentLevel === 1) {
                return this.$refs.level1Header.$refs.mobileBackButton;
            }
            return this.$refs[`levelHeader${this.currentOpenId}`][0].$refs.mobileBackButton;
        },
        currentLevelEnd() {
            if (this.currentLevel === 1) {
                return this.$refs.courtesyMenu.$refs.lastTabStop;
                // eslint-disable-next-line no-magic-numbers
            } else if (this.currentLevel === 2) {
                return this.$refs[`topTasks${this.currentOpenId}`][0].$refs.lastTabStop;
            }
            return this.currentLevelItems[this.currentLevelItems.length - 1];
        },
        currentLevelItems() {
            return this.$refs[`list${this.currentOpenId}`][0].querySelectorAll('a, button');
        },
        currentOpenParent() {
            return this.$refs[this.currentOpenId][0];
        },
        lastWideItem() {
            const lastTopLevelTab = '5';
            if (this.level1Open === lastTopLevelTab) {
                return this.$refs.topTasks5[0].$refs.lastTabStop;
            }
            return this.$refs['5'][0];
        },
        menuOpen() {
            return this.menuToggleOpen;
        },
    },
    mounted() {
        //TODO: Move this to API
        const generateId = (index, prefix) =>
            prefix ? `${prefix}${index}` : `${index}`;

        const walkTree = (items, prevId) => {
            const newItems = [];
            items.forEach((element, index, list) => {
                const thisId = generateId(index, prevId);
                let newEl = element;
                newEl.id = thisId;
                if (index === list.length - 1) {
                    newEl.last = true;
                }
                newItems.push(newEl);
                if (element.children) {
                    walkTree(element.children, thisId);
                }
            });
            return items;
        };
        this.navigation = walkTree(this.navigation);
        //HACK
        this.$forceUpdate();
        const isItWide = window.matchMedia('(min-width: 715px)');
        const setWide = () => {
            this.isWide = isItWide.matches;
            if (!isItWide.matches && this.currentLevel > 1) {
                this.toggleMobileMenu();
            }
        };
        isItWide.addListener(setWide);
        this.$nextTick(() => setWide(isItWide));
    },
    methods: {
        /* Given a level, set state to open (id of item) or closed (null) */
        setLevelOpenState(level, openState) {
            this[`level${level}Open`] = openState;
        },
        toggleMobileMenu() {
            if ( this.menuToggleOpen ) {
                //close
                this.menuToggleOpen = false;
                body.classList.remove('--scroll-lock');
                this.$nextTick(() => this.$refs.mobileOpenButton.focus());

            } else {
                //open
                this.menuToggleOpen = true;
                body.classList.add('--scroll-lock');
                this.$nextTick(() => this.$refs.level1Header.$refs.mobileBackButton.focus());

            }
        },
        closeKids(level) {
            const maxLevels = 3;
            let levelsLeft = level;
            while (levelsLeft < maxLevels) {
                levelsLeft++;
                this[`level${levelsLeft}Open`] = null;
            }
        },
        /* Toggle the open or closed state of a menu level */
        toggleOpen(id, level, $event) {

            const thisLevelOpen = this[`level${level}Open`];

            // HACK to prevent loss of focus
            if ( id.length === 1 && this.isWide ) {
                //tabs that open menu loose focus to body without this
                if ($event) {
                    $event.preventDefault();
                }
                window.setTimeout(() => {
                    this.$el.querySelector(`[aria-controls="menu-item_${id}"]`).focus();
                }, 1);
            }

            if (thisLevelOpen === id) {
                const parent = this.currentOpenParent;
                // Close this level
                this.setLevelOpenState(level, null);
                this.closeKids(level);
                if (!this.isWide) {
                    this.$nextTick(() => parent.focus());
                }
            } else {
                const startingLevel = this.currentLevel;
                // Set this level to current list/id
                this.setLevelOpenState(level, id);
                if (startingLevel > 1) {
                    //We are switching, not opening fresh
                    this.closeKids(level);
                }
                if (!this.isWide) {
                    this.$nextTick(() => this.currentLevelStart.focus());
                }
            }

        },
        goBack(level) {
            //put focus back on previous level item
            //toggle close this level
            const openParentId = this[`level${level - 1}Open`];
            if (level > 1) {
                this.$refs[`${openParentId}`][0].focus();
                this.toggleOpen(openParentId, level - 1);
            } else {
                //close the whole menu
                this.setLevelOpenState(1, null);
                if ( !this.isWide ) {
                    this.toggleMobileMenu();
                }
            }
        },
        getIcon(itemLevel1Index) {
            const iconNames = [
                'safety-health',
                'claims',
                'patient-care',
                'insurance',
                'rights', //this does not match url but is the correct icon name
                'licensing-permits',
            ];

            return iconNames[itemLevel1Index];
        },
        setDropdownOpenState() {
            //This is set after animations are complete
            this.dropdownOpen = this.currentLevel > 1;
        },
        sendFocusToEnd($event) {
            $event.preventDefault();
            this.currentLevelEnd.focus();
        },
        sendFocusToStart($event) {
            const lastTab = this.$refs[5][0];
            const lastTopLevelOpen = this.level1Open === '5';

            if ( this.isWide && this.dropdownOpen ) {
                const lastDropdownChildItem = this.$refs.topTasks5[0].$refs.lastTabStop;
                const doDesktopTrap = (lastTopLevelOpen && $event.currentTarget === lastDropdownChildItem)
                || ( !lastTopLevelOpen && $event.currentTarget === lastTab);

                if (doDesktopTrap) {
                    // Only trap focus on mega-menu if we are at very last item in last dropdown
                    // or we are on the last tab and its children are not open
                    $event.preventDefault();
                    this.$refs['0'][0].focus();
                }
            } else if ( !this.isWide ) {
                // Trap focus on mobile because other parts of menu and site are hidden
                $event.preventDefault();
                this.currentLevelStart.focus();
            }
        },
        sendFocusToTopTasks($event) {
            $event.preventDefault();
            this.$refs[`topTasks${this.level1Open}`][0].$refs.firstTabStop[0].focus();
        },
        escapeMenu() {
            if ( this.currentLevel > 1 && this.dropdownOpen ) {
                this.level1Open = null;
            }
        },
    },
}; </script>